import React from "react";
import { Router } from "@reach/router";
import OrderResult from '../components/OrderResult'
import Layout from '../components/Layout'
import Helmet from "react-helmet"
import Breadcrumb from "../components/Breadcrumb"
import {I18nextContext, useTranslation} from 'gatsby-plugin-react-i18next'
import favicon from "../../static/favicon.svg"
import {useSiteMetadata} from "../hooks/use-site-metadata";


export default function ResultPage() {
  const { language } = React.useContext(I18nextContext);
  const { t } = useTranslation('Index');
  const { title, description } = useSiteMetadata();
  return (
    <Layout>
      <Helmet>
        <html lang={language}/>
        <title>{t(title)}</title>
        <link rel="icon" href={favicon} />
        <meta name="title" content={t(title)} />
        <meta name="og:title" content={t(title)} />
        <meta name="og:site_name" content={title} />
        <meta name="description" content={t(description)} />
        <meta name={`robots`} content={`noindex, nofollow`} />
        <meta name="cache-control" content="no-cache"/>
      </Helmet>
        <Router basepath={`/${language}/checkout/result/`}>
          <Breadcrumb path="/" step={'confirmacion'}/>
        </Router> 
	      <Router basepath={`/${language}/checkout/result/`}>
          <OrderResult path="/"></OrderResult>
        </Router>
    </Layout>
  )
}
